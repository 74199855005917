import React, { useContext, Component, Fragment, useState, useEffect, useRef } from "react";
import Clock from "../components/Clock";
import Footer from '../components/footer';
import { ethers } from "ethers";
import { createGlobalStyle } from 'styled-components';
import { GlobalContext, globalState } from "components/app";
import CharacterNFTABI from '../../ContractABI/CharacterNFTABI'
import MarketplaceABI from '../../ContractABI/MarketplaceABI'
import { Redirect } from "@reach/router";
import { marketplaceAddress, characterAddress, delay } from "ContractABI/constants";
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
    border-bottom: solid 1px #ccc !important;
  }
`;




const Colection = function () {


    const { isLogin, walletAddress, selectedNFT } = useContext(GlobalContext)
    const [owner, setOwner] = useState(null);
    const [isListed, setIsListed] = useState(false);
    const [NFTData, setnftData] = useState(null);
    const [showModal, setShowModal] = useState("none");
    const inputRef = useRef(null);
    const [isLoadingState, setIsLoadingState] = useState(false);
    const [history, setHistory] = useState([]);
    var provider = new ethers.providers.Web3Provider(window.ethereum);
    var signer = provider.getSigner();
    var MarketPlaceContract = new ethers.Contract(
        marketplaceAddress,
        MarketplaceABI,
        signer
    );
    var CharacterContract = new ethers.Contract(
        characterAddress,
        CharacterNFTABI,
        signer
    );
    console.log(selectedNFT);

    const loadJson = async () => {

        let owner = await CharacterContract.ownerOf(selectedNFT.tokenId);

        setOwner(owner.toLowerCase());
        const jsonData = await fetch(selectedNFT.uri);
        const newJson = await jsonData.json();
        const status = await MarketPlaceContract.marketPlaceListing(characterAddress, selectedNFT.tokenId);
        const approved = await CharacterContract.getApproved(selectedNFT.tokenId);

        newJson.isAvailable = status.isAvailable;
        newJson.isApproved = (approved.toLowerCase() === marketplaceAddress.toLocaleLowerCase())
        console.log(newJson);

        const h = await MarketPlaceContract.getHistory();

        let historyList = [];
        for (let i = 0; i < h.length; i++) {
            console.log(h[i],selectedNFT.tokenId,characterAddress);
            if (h[i].contractAddress == characterAddress && h[i].tokenID == selectedNFT.tokenId) {

                historyList.push({
                    owner: h[i].owner,
                    price: ethers.utils.formatEther(h[i].price),
                    action: h[i].action
                });
            }
        }
        console.log(historyList);
        setHistory(historyList);
        setnftData(newJson);
        setIsLoadingState(false);
    }
    const ListNow = async () => {
        if (inputRef.current.value > 0) {
            const recipt = await MarketPlaceContract.ListNFt(characterAddress, selectedNFT.tokenId, ethers.utils.parseEther(inputRef.current.value));
            setIsLoadingState(true);
            await delay(20000);
            console.log(recipt);
            loadJson();
        }
    }

    const Delist = async () => {

        const recipt = await MarketPlaceContract.DelistNFT(characterAddress, selectedNFT.tokenId);
        setIsLoadingState(true);
        await delay(20000);
        console.log(recipt);
        loadJson();

    }
    useEffect(() => {
        loadJson();
    }, []);
    const Approve = async () => {

        const recipt = await CharacterContract.approve(marketplaceAddress, selectedNFT.tokenId);
        setIsLoadingState(true);
        await delay(20000);
        console.log(recipt);
        loadJson();

    }
    if (!isLogin) {
        return <Redirect to="/" />
    }
    return (
        NFTData != null &&
        <div>

            <GlobalStyles />

            <section className='container'>
                <div className='row mt-md-5 pt-md-4'>

                    <div className="col-md-6 text-center">
                        {NFTData === null ? <img src="./img/1.png" className="img-fluid img-rounded mb-sm-30" alt="" /> : <img src={NFTData.image} className="img-fluid img-rounded mb-sm-30" alt="" />}
                    </div>
                    <div className="col-md-6">
                        <div className="item_info">

                            <div>
                                <span style={{ fontSize: "20px", fontWeight: "bolder", color: "black" }}>Token ID : </span> <span>{selectedNFT.tokenId}</span>
                            </div>
                            <div>
                                <span style={{ fontSize: "20px", fontWeight: "bolder", color: "black" }}>Contract : </span> <span>{selectedNFT.contract}</span>
                            </div>
                            <div>
                                <span style={{ fontSize: "20px", fontWeight: "bolder", color: "black" }}>Name :  </span> {NFTData && <span>{NFTData.name} </span>}
                            </div>
                            <div>
                                <span style={{ fontSize: "20px", fontWeight: "bolder", color: "black" }}>DNA :  </span> {NFTData && <span>{NFTData.dna} </span>}
                            </div>

                            <br />
                            {
                                NFTData &&
                                <p>{NFTData.description}</p>

                            }


                            <h4> Attributes </h4>
                            <div className="spacer-40"></div>

                            <div className="de_tab">

                                <ul className="de_nav">
                                    {
                                        NFTData && NFTData.attributes.map((d, i) => {
                                            return (d.value === "None") ? null : <li style={{ margin: '4px' }} id="Mianbtn"> <span>{d.value}</span></li>
                                        })
                                    }
                                </ul>


                            </div>
                            {
                                (isLoadingState) ?
                                    <div>
                                        <span style={{ fontSize: "20px", fontWeight: "bolder", color: "black" }}> Processing.. </span>
                                    </div>
                                    :

                                    (!NFTData.isApproved)
                                        ?
                                        <div onClick={() => Approve()} className="btn-main" >
                                            Approve
                                        </div>
                                        :
                                        (NFTData.isAvailable) ?
                                            <div onClick={() => Delist()} className="btn-main" >
                                                Delist
                                            </div>
                                            :
                                            <div onClick={() => setShowModal("block")} className="btn-main" >
                                                List for Sale
                                            </div>



                            }

                            <br />
                            <ul class="list-group">
                                {
                                    history.map(d => {
                                        return (<li class="list-group-item"><div ><span style={{ fontSize: '12px' }}>{d.owner}</span> <span style={{ fontWeight: 'bold' }}>{d.action}</span> {d.tokenID} for {d.price} BNB</div>  </li>)
                                    })

                                }
                            </ul>

                        </div>
                    </div>

                </div>


            </section>

            <div style={{ display: showModal }} className="modal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Please enter price</h5>
                            <button type="button" onClick={() => setShowModal("none")} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span aria-hidden="true">Price in BNB</span>
                            <input ref={inputRef} style={{ marginLeft: "8px" }} type={"number"} placeholder="0.1BNB" />
                        </div>
                        <div className="modal-footer">
                            <button style={{ color: "black" }} onClick={() => ListNow()} type="button" className="btn">List Now</button>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </div>

    );
}
export default Colection;