import React, { Fragment, useContext, useEffect, useState } from 'react';
import SliderMainParticle from '../components/SliderMainParticle';

import CarouselNew from '../components/CarouselNew';

import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { ethers } from 'ethers';
import { GlobalContext } from "components/app";

import CharacterNFTABI from '../../ContractABI/CharacterNFTABI'
import MarketplaceABI from '../../ContractABI/MarketplaceABI'
import { Link } from "@reach/router";
import { marketplaceAddress,characterAddress } from 'ContractABI/constants';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar .search #quick_search{
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;


const Homeone = () => {
  const { isLogin, setIsLogin, listed, setListed } = useContext(GlobalContext);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {

    async function fetchData() {
      var provider = new ethers.providers.Web3Provider(window.ethereum);
      var signer = provider.getSigner();
      var MarketPlaceContract = new ethers.Contract(
        marketplaceAddress,
        MarketplaceABI,
        signer
      );
      var CharacterContract = new ethers.Contract(
        characterAddress,
        CharacterNFTABI,
        signer
      );

      let listData = [];
      let data = await MarketPlaceContract.getListed()
 
      //console.log(data);
     // let mainData = await MarketPlaceContract.marketPlaceListing("0xd1C6393D8C42Af6dAF7bB11C70e0988108499EB3","32");
     // console.log("Main Data",mainData);
      for (let i = 0; i < data.length; i++) {
        //console.log(ethers.utils.formatEther(data[i].price));
        let jsonData = await CharacterContract.tokenURI(Number(data[i].tokenID))
        const res = await fetch(jsonData);
        const d = await res.json();
        let status = await MarketPlaceContract.marketPlaceListing(data[i].contractAddress,data[i].tokenID)
        console.log(status);
       
        let singleEntry = {
          contractAddress: data[i].contractAddress,
          isAvailable: status.isAvailable,
          owner: data[i].owner,
          tokenID: Number(data[i].tokenID),
          price: ethers.utils.formatEther(status.price),
          jsonData: d
        }
        listData.push(singleEntry);
       
      }

      setListed(listData);
      setLoading(false);
    }
    fetchData();

  }, [])
  return (
    <div>
      <GlobalStyles />
      <section className="jumbotron no-bg">
        <SliderMainParticle />
      </section>

      {
        isLogin && !isLoading &&
        <Fragment>
          {/* <ShowMinterButton/> */}
          <br />
          <br /><br /><br />
          <section className='container no-top'>
            <div className='row'>
              <div className='col-lg-12 text-center'>
                <h2 className='style-2'>Browse Category</h2>

                <div className="small-border"></div>
              </div>
            </div>
            <div className='container px-0'>
              <div className="row">
                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Characters</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Game Assets</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Music</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Memberships</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>VIP PASS</h4>
                  </span>
                </div>

                <div className="col-lg-2 col-sm-4 col-6 mb30">
                  <span className="box-url">
                    <i className="fa fa-image"></i>
                    <h4>Community Tickets</h4>
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section className='container no-top no-bottom'>
            <div className='row'>
              <div className="spacer-double"></div>
              <div className='col-lg-12 mb-2'>
                <h2>Items On Sale</h2>
              </div>
            </div>
            <CarouselNew />
            <Link to="nfts">
          
            <div style={{textAlign:'center',marginTop: '80px'}}>
              <span style={{ display:'inline',textAlign: 'center' }} className="btn-main">
                <span >Show All</span>
              </span>
            </div>
            </Link>
          </section>

        </Fragment>

      }



      <br />
      <Footer />

    </div>
  )

};
export default Homeone;