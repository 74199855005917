import React, { useEffect } from 'react';
import { ethers } from "ethers";

import CharacterNFTABI from '../../ContractABI/CharacterNFTABI'

 
const Pricing= () => {
    var provider = new ethers.providers.Web3Provider(window.ethereum);
    var signer = provider.getSigner();
    var contract = new ethers.Contract(
        "0x9fff68834C8A6C80F5e42262c7a3c78F0d2AACFB",
        CharacterNFTABI,
        signer
    );
    useEffect(()=>{
        // here i need to check totalMinted already
        getAllMinted();

    },[]);

    const getAllMinted = async () =>{
        const totalMinted = await contract.getAllMinted().toNumber();
        console.log(totalMinted);
    }
    const MintCommonNFT = async ()=>{
        //const owner = await contract.owner();
        
    }

    const MintRareNFT=()=>{

    }

    return(
  <section className='container'>
        <div className="row sequence">

          <div className="col-lg-6 col-md-6 col-sm-12 sq-item wow">
              <div className="pricing-s1 mb30">
                  <div className="top">
                      <h2>Mint Common NFT</h2>
                      <p className="plan-tagline">0.1 BNB</p>            
                  </div>
                  <div className="mid text-light bg-color">
                      <p className="price">
                         
                          <span className="m opt-1">0.1 BNB</span>
                        
                        
                      </p>               
                  </div>
                  
                  <div className="bottom">
                      <ul>
                          <li><i className="fa fa-check"></i>1</li>
                          <li><i className="fa fa-check"></i>2</li>
                          <li><i className="fa fa-check"></i>3</li>
                          <li><i className="fa fa-check"></i>4</li>
                          <li><i className="fa fa-check"></i>5</li>
                          <li><i className="fa fa-check"></i>6</li>
                      </ul>
                  </div>
                  
                  <div className="action">
                      <a href="register.html" className="btn-main">Mint Now</a>
                  </div>
              </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 sq-item wow">
              <div className="pricing-s1 mb30">
                  <div className="top">
                      <h2>Mint Rare NFT</h2>
                      <p className="plan-tagline">1 BNB</p>   
                  </div>
                      <div className="mid text-light bg-color">
                      <p className="price">
                         
                          <span className="m opt-1">My Rare NFTs</span>
                         
                      </p>     
                  </div>
                  <div className="bottom">
                      <ul>
                         <li><i className="fa fa-check"></i>1</li>
                          <li><i className="fa fa-check"></i>2</li>
                          <li><i className="fa fa-check"></i>3</li>
                          <li><i className="fa fa-check"></i>4</li>
                          <li><i className="fa fa-check"></i>5</li>
                          <li><i className="fa fa-check"></i>6</li>
                      </ul>
                  </div>
                  
                  <div className="action">
                      <a href="register.html" className="btn-main">Mint NFT Now</a>
                  </div>
              </div>
          </div>
       

        </div>
    </section>)
  
};
export default Pricing;