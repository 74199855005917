import React, { useContext, Component, Fragment, useState, useEffect, useRef } from "react";
import Clock from "../components/Clock";
import Footer from '../components/footer';
import { ethers } from "ethers";
import { createGlobalStyle } from 'styled-components';
import { GlobalContext, globalState } from "components/app";
import CharacterNFTABI from '../../ContractABI/CharacterNFTABI'
import MarketplaceABI from '../../ContractABI/MarketplaceABI'
import { Redirect } from "@reach/router";
import { marketplaceAddress, characterAddress, delay } from "ContractABI/constants";
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
    border-bottom: solid 1px #ccc !important;
  }
`;




const Buy = function () {


    const { isLogin, walletAddress, selectedNFT } = useContext(GlobalContext)
    const [owner, setOwner] = useState(null);
    const [isListed, setIsListed] = useState(false);
    const [NFTData, setnftData] = useState(null);
    const [showModal, setShowModal] = useState("none");
    const [history,setHistory] = useState([]);
    const inputRef = useRef(null);
    const [isLoadingState, setIsLoadingState] = useState(false);
    var provider = new ethers.providers.Web3Provider(window.ethereum);
    var signer = provider.getSigner();
    var MarketPlaceContract = new ethers.Contract(
        marketplaceAddress,
        MarketplaceABI,
        signer
    );
    var CharacterContract = new ethers.Contract(
        characterAddress,
        CharacterNFTABI,
        signer
    );
    console.log(selectedNFT);

    const loadJson = async () => {

        let owner = await CharacterContract.ownerOf(selectedNFT.tokenID);
      
        const status = await MarketPlaceContract.marketPlaceListing(characterAddress, selectedNFT.tokenID);
        const h = await MarketPlaceContract.getHistory();
   
        let historyList =[];
        for(let i=0;i<h.length;i++){
            if(h[i].contractAddress == characterAddress && h[i].tokenID == selectedNFT.tokenID){
             
                historyList.push({
                    owner : h[i].owner,
                    price : ethers.utils.formatEther(h[i].price),
                    action : h[i].action
                });
            }
        }
        let d = selectedNFT;
        d.owner = owner;
        console.log(historyList);
        d.price = ethers.utils.formatEther(status.price)
        setHistory(historyList);
        setnftData(selectedNFT);
        setIsLoadingState(false);
    }



    const BuyNow = async (contractAddress, tokenID) => {
        console.log(contractAddress, tokenID,);
        let receipt = await MarketPlaceContract.BuyNFT(contractAddress, tokenID, { value: ethers.utils.parseEther(NFTData.price) })
        console.log(receipt);

        setIsLoadingState(true);
        await delay(20000);

        loadJson();
    }
    useEffect(() => {
        loadJson();
    }, []);

    if (!isLogin) {
        return <Redirect to="/" />
    }
    return (
        NFTData != null &&
        <div>

            <GlobalStyles />

            <section className='container'>
                <div className='row mt-md-5 pt-md-4'>

                    <div className="col-md-6 text-center">
                        {NFTData === null ? <img src="./img/1.png" className="img-fluid img-rounded mb-sm-30" alt="" /> : <img src={NFTData.jsonData.image} className="img-fluid img-rounded mb-sm-30" alt="" />}
                    </div>
                    <div className="col-md-6">
                        <div className="item_info">

                            <div>
                                <span style={{ fontSize: "20px", fontWeight: "bolder", color: "black" }}>Token ID : </span> <span>{selectedNFT.tokenID}</span>
                            </div>
                            <div>
                                <span style={{ fontSize: "20px", fontWeight: "bolder", color: "black" }}>Contract : </span> <span>{selectedNFT.contractAddress}</span>
                            </div>
                            <div>
                                <span style={{ fontSize: "20px", fontWeight: "bolder", color: "black" }}>Name :  </span> {NFTData && <span>{NFTData.jsonData.name} </span>}
                            </div>
                            <div>
                                <span style={{ fontSize: "20px", fontWeight: "bolder", color: "black" }}>DNA :  </span> {NFTData && <span>{NFTData.jsonData.dna} </span>}
                            </div>

                            <br />
                            {
                                NFTData &&
                                <p>{NFTData.jsonData.description}</p>

                            }


                            <h4> Attributes </h4>
                            <div className="spacer-40"></div>

                            <div className="de_tab">

                                <ul className="de_nav">
                                    {
                                        NFTData && NFTData.jsonData.attributes.map((d, i) => {
                                            return (d.value === "None") ? null : <li style={{ margin: '4px' }} id="Mianbtn"> <span>{d.value}</span></li>
                                        })
                                    }
                                </ul>


                            </div>
                            {
                                (isLoadingState) ?
                                    <div>
                                        <span style={{ fontSize: "20px", fontWeight: "bolder", color: "black" }}> Processing.. </span>
                                    </div>
                                    :
                                    (NFTData.owner.toLowerCase() !== walletAddress.toLocaleLowerCase()) ?
                                        <>
                                            <div onClick={() => BuyNow(characterAddress, selectedNFT.tokenID)} className="btn-main" >
                                                Buy for {NFTData.price} BNB
                                            </div>

                                            <br />
                                            <ul class="list-group">
                                                {
                                                    history.map(d=>{
                                                        return( <li class="list-group-item"><div ><span style={{fontSize:'12px'}}>{d.owner}</span> <span style={{fontWeight:'bold'}}>{d.action}</span> {d.tokenID} for {d.price} BNB</div>  </li>)
                                                    })
                                                   
                                                }
                                            </ul>
                                        </>
                                        :
                                        null

                            }


                        </div>
                    </div>

                </div>


            </section>


            <Footer />

        </div>

    );
}
export default Buy;