import React, { useContext } from 'react';
import Pricing from '../components/pricing';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { GlobalContext } from 'components/app';
import { Redirect } from '@reach/router';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #FAF6F1;
  }
`;

const Price = () => {
  const {isLogin} = useContext(GlobalContext);

  if(!isLogin) {return(<Redirect to="/"/>)}
  return (
    <div>
      <GlobalStyles />
      <section className='jumbotron breadcumb no-bg'>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12 text-center'>
                <h1>Mint Hamster Vanilla</h1>

              </div>
            </div>
          </div>
        </div>
      </section>

      <Pricing />
      <Footer />
    </div>

  )
}
export default Price;