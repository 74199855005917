import React, { Component, useContext } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Clock from "./Clock";
import { Link } from "@reach/router";
import { GlobalContext } from "components/app";
import { ethers } from 'ethers';

import { NavLink } from 'react-bootstrap';

import { ShowMinterButton } from 'components/menu/header';
import { Redirect } from '@reach/router';
import CharacterNFTABI from '../../ContractABI/CharacterNFTABI'
import MarketplaceABI from '../../ContractABI/MarketplaceABI'
import { marketplaceAddress,characterAddress } from "ContractABI/constants";
const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;



class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

const Responsive = () =>{
  const {listed,walletAddress,setSelectedNFT} = useContext(GlobalContext);
var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: 300,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    }

    const BuyNow = async (contractAddress,tokenID,price)=>{
      var provider = new ethers.providers.Web3Provider(window.ethereum);
      var signer = provider.getSigner();
      var MarketPlaceContract = new ethers.Contract(
        marketplaceAddress,
       MarketplaceABI,
        signer
      );
      var CharacterContract = new ethers.Contract(
        characterAddress,
        CharacterNFTABI,
        signer
      );
      let receipt = await MarketPlaceContract.BuyNFT(contractAddress, tokenID,{ value: ethers.utils.parseEther(price) }) 
      console.log(receipt);
 } 
    return (
        <div className='nft'>
          <Slider {...settings}>

            {
              listed.map(d=>{
               return (walletAddress.toLowerCase() !== d.owner.toLowerCase() && d.isAvailable) && <CustomSlide className='itm' index={1}>
                <div className="d-item">
                <Link onClick={()=>{setSelectedNFT(d)}} to="/ButItem">
                  <div className="nft__item" >
                   
                        <span >
                            <h4>NAME : {d.jsonData.name}</h4>
                        </span>
                        <span >
                            <h4>TOKEN ID : {d.tokenID}</h4>
                        </span>
                        <span >
                            <h4>EDITION : {d.jsonData.edition}</h4>
                        </span>
                      <div className="nft__item_wrap">
                        <Outer>
                        
                          <span>
                              <img src={d.jsonData.image} className="lazy nft__item_preview" alt=""/>
                          </span>
                     
                        </Outer>
                      </div>
                      <div className="nft__item_info">
                          
                          <div className="nft__item_price">
                             PRICE : {d.price} BNB
                          </div>
                          <div className="nft__item_action">
                              <span >Buy Now</span>
                          </div>
                                               
                      </div> 
                  </div>
                  </Link>
                </div>
                </CustomSlide>
              })
            }
          

          </Slider>
        </div>
    );
  
}

export default Responsive
